import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Form2.css';

const Form2: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Validate form fields
    if (!selectedDate || !name || !email || !message) {
      alert('Please fill out all fields.');
      return;
    }

    const formData = {
      name,
      email,
      message,
      date: selectedDate.toISOString(),
    };

    fetch('https://formsubmit.co/verdir@verdir.is', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          alert('Form submitted successfully!');
          setName('');
          setEmail('');
          setMessage('');
          setSelectedDate(new Date());
        } else {
          alert('Form submission failed.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('There was an error submitting the form.');
      });
  };

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <div className="form-group">
        <label htmlFor="name">Nafn:</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="email">Netfang:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="message">Skilaboð:</label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="date">Veldu tíma:</label>
        <DatePicker
          selected={selectedDate}
          onChange={(date: Date | null) => setSelectedDate(date)}
          showTimeSelect
          dateFormat="Pp"
          className="date-picker"
        />
      </div>

      <button type="submit" className="submit-button">
        Senda fyrirspurn
      </button>
    </form>
  );
};

export default Form2;