import React from 'react';
import './Navbar.css';
import Logo from '../../assets/headerv2logo.svg';
import { NavLink, Link } from 'react-router-dom';

const Navbar: React.FC = () => {
  return (
    <nav className="navbar">
      <div className="navbar-left">
        <Link to="/">
          <img src={Logo} alt="Verðir" className="logo" />
        </Link>
      </div>
      <div className="navbar-center">
      </div>
      <div className="navbar-right">
        <NavLink 
        to="/time-booking"
        className="book-btn"
        >
          <span>Bóka tíma</span>
        </NavLink>
        <a href="https://ineosgrenadier.com/en/is/the-vehicle/vehicle-configurator"
        target="_blank"  /* Opens the link in a new tab */
        rel="noopener noreferrer"
         className="enquire-btn">Búðu til þinn eigin bíl</a>
      </div>
    </nav>
  );
}

export default Navbar;