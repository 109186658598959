import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import Body from './components/Body/Body';
import Footer from './components/Footer/Footer';
import Form from './components/TimeBookingForm/Form2';
import FootNote from './components/Footer/FootNote';
import Slideshow from './components/Body/Slideshow';
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from 'react-router-dom';

import image1 from './assets/SlideshowMyndir/Image 3.jpeg';
import image3 from './assets/SlideshowMyndir/Image 5.jpeg';
import image4 from './assets/SlideshowMyndir/Image 6.jpeg';
import image5 from './assets/SlideshowMyndir/Image 7.jpeg';
import Thjonusta from './components/Thjonusta/Thjonusta';
import UmOkkur from './components/UmOkkur/UmOkkur';

const imageUrls = [image1, image3, image4, image5]; 


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={
        <div className="App">
          <Header />
          <Body />
          <Slideshow images={imageUrls}/>
          <Footer />
          <FootNote />
        </div>
      } />
      <Route path="/time-booking" element={
        <div className="App">
          <Header />
          <Form />
          <Footer />
          <FootNote />
        </div>
      } />
      <Route path="/thjonusta" element={
        <div className="App">
          <Header />
          <Thjonusta />
          <Footer />
          <FootNote />
        </div>
      } />
      <Route path="/um-verdir" element={
        <div className="App">
          <Header />
          <UmOkkur />
          <Footer />
          <FootNote />
        </div>
      } />
    </>
  )
);



function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
