import React from "react";
import Navbar from "./Navbar";
import Actionbar from "./Actionbar";

const Header: React.FC = () => {
  return (
    <header>
      <Navbar />
      <Actionbar />
    </header>
  );
}

export default Header;