import React from "react";
import "./FootNote.css";

const FootNote: React.FC = () => {
  return (
    <div className="footnote">
      <p>Verðir ehf. | Gylfaflöt 15, 112 Reykjavík | Kennitala: 480222-1800</p>
    </div>
  );
};

export default FootNote;