import React from "react";
import './Footer.css';
import fbIcon from './fbVerdir.svg';
import igIcon from './igVerdir.svg';

const Footer: React.FC = () => {
    return (
        <div className="footer-container">
            <div className="footer-column-1">
                <h1>Vertu í sambandi</h1>
                <h3>558-2000</h3>
                <h4>VERDIR@VERdir.is</h4>
            </div>
            <div className="footer-column-2">
            <h3>Opnunartími</h3>
                <h4>Mánudagar - Föstudagar: 13:00 - 17:00</h4>
                <ol className="socials">
                    <li>
                        <a href="https://www.facebook.com/profile.php?id=61554240580788">
                            <img id="fbIcon" src={fbIcon} alt="Facebook" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/verdir_ineosgrenadier/">
                            <img id="igIcon" src={igIcon} alt="Facebook" />
                        </a>
                    </li>
                </ol>
            </div>
        </div>
        
    );
};

export default Footer;