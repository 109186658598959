import React from 'react';
import './ActionBar.css';
import { NavLink} from 'react-router-dom';

const ActionBar: React.FC = () => {
  return (
    <div className="actionbar">
      <NavLink 
      to="/thjonusta"
      className="">
        Verkstæðisþjónusta
      </NavLink>

      <a 
        href="https://bilasolur.is/SearchResults.aspx?id=91a0a91a-a016-4dd6-83c1-aa8988e05518"
        target="_blank"  
        rel="noopener noreferrer" 
        className=""
      >
        Bílarnir okkar
      </a>

      <NavLink 
      to="/myndir"
      className="">
        Myndir
      </NavLink>

      <NavLink 
      to="/um-verdir"
      className="">
        Um okkur
      </NavLink>
    </div>
  );
}

export default ActionBar;
