import React from "react";
import "./UmOkkur.css";
import umOkkurImage from "../../assets/Image 4.jpeg";
import umOkkurImage2 from "../../assets/d9f3ccb4529421229add0116b79fa8cb1bbc1d19_50.jpeg"

const UmOkkur: React.FC = () => {
    return (
        <div className="pageContainer">
            <div className="umOkkurContainer1">
                <div className="textContainer">
                    <h2>Verðir ehf.</h2> {/* Placeholder Header */}
                    <p>Verðir ehf er þjónustufyrirtæki og umboðsaðili fyrir INEOS Grenadier á Íslandi. 
                        Umboðið er staðsett á Gylfaflöt 15 í Grafarvogi og þar er bæði sýningarsalur og verkstæðisþjónusta. 
                        Við munum taka vel á móti þér og gerum dag hvern okkar allra besta. </p>
                </div>
                <div className="imageContainer">
                    <img src={umOkkurImage} alt="Um okkur" />
                </div>
            </div>
            <div className="umOkkurContainer2">
                <div className="textContainer">
                    <h2>INEOS</h2> {/* Placeholder Header */}
                    <p>Framleiðsla INEOS Grenadier er í glæsilegri verksmiðju í bænum Hambach í Frakklandi, 
                        þróun og hönnun fór m.a. fram í Þýskalandi, Austurríki, Bretlandi og Íslandi. 
                        Til Íslands komu prótótýpur af bílnum til reynsluaksturs áður en framleiðsla 
                        fyrir neytendamarkað fór af stað.</p>
                </div>
                <div className="imageContainer">
                    <img src={umOkkurImage2} alt="Grenadier" />
                </div>
            </div>
        </div>
    );
};

export default UmOkkur;
