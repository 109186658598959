import React from "react";
import "./Body.css";

const Body: React.FC = () => {
  return (
    <div className="coverphoto-container">
      <div className="body-content">
        <div className="body-texti">
          <h1 className="efri-body-texti">Hvert</h1>
          <h1 className="nedri-body-texti">sem er</h1>
          <a className="body-button"
           href="https://ineosgrenadier.com/en/is/the-vehicle/vehicle-configurator"
           target="_blank"  /* Opens the link in a new tab */
            rel="noopener noreferrer"
           >Hannaðu þitt eintak</a>
        </div>
      </div>
    </div>
  );
};

export default Body;
