import React from "react";
import './Thjonusta.css';
import thjonustaImage from '../../assets/thjonusta-cover3.jpg';
import { NavLink } from "react-router-dom";

const Thjonusta: React.FC = () => {
    return (
        <div className="thjonusta-page">
            <div className="thjonusta-header">
                <h1>Þjónustan</h1>
            </div>
            <div className="thjonusta-container">
                <div className="thjonusta-image">
                    <img src={thjonustaImage} alt="Þjónusta"/>
                </div>
                <div className="thjonusta-content">
                    <p>Verkstæði Varða ehf er á Gylfaflöt 15 í Reykjavík. Þar tekur á móti þér reynslumikið starfsfólk okkar sem meðal annars hefur fengið þjálfun hjá INEOS Grenadier. Auk þess að sinna allri hefðbundinni þjónustu sér verkstæðið einnig um breytingar á INEOS Grenadier bílunum hvort sem um er að ræða 37“ eða 44“ breytingar.</p>
                </div>
                <NavLink 
        to="/time-booking"
        className="thjonusta-book-btn"
        >
          <span>Bóka tíma</span>
        </NavLink>
            </div>
        </div>
    );
}

export default Thjonusta;